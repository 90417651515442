/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { ApiAuthService } from '@core/services/api-auth.service';
import { TokenService } from '@core/services/token.service';
import { AuthErrorExtensions } from '@core/enums/auth.enum';
import { environment } from '@environments/environment';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private readonly tokenService: TokenService,
    private readonly apiAuthService: ApiAuthService,
    private dialog: MatDialog
  ) {}

  private isRefreshing: boolean = false;
  private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.body?.operationName === 'Login' || request.body?.operationName === 'forgotPassword') {
      return next.handle(request);
    }
  
    if (request.url.startsWith(environment.apiUrl) && !request.url.match(/\.(png|jpg|jpeg|svg|gif|webp|ico)$/)) {
      const token = this.tokenService.getAccess();
      
      if (!token) {
        return EMPTY;
      }
  
      const authToken = this.addTokenHeader(request, token);
      
      return next.handle(authToken).pipe(
        switchMap((res) => {
          if (res instanceof HttpResponse && res.body.errors) {
            if (res.body.errors[0].originalError.statusCode === AuthErrorExtensions.Unauthenticated && !this.isRefreshing) {
              this.isRefreshing = true;
              return this.handleTokenRefresh(request, next);
            } else if (res.body.errors[0].originalError.statusCode === AuthErrorExtensions.Unauthenticated && this.isRefreshing) {;
              return this.refreshTokenSubject.pipe(
                filter((newToken) => newToken !== null),
                take(1),
                switchMap((newToken) => {
                  const retryRequest = this.addTokenHeader(request, newToken!);
                  return next.handle(retryRequest);
                })
              );
            }
          }
          return of(res);
        }),
      );
    }
  
    return next.handle(request);
  }
  
  private addTokenHeader(request: HttpRequest<any>, token: string | undefined) {
    return request.clone({ headers: request.headers.set('Authorization', `Bearer ${token}`) });
  }

  private handleTokenRefresh(request: HttpRequest<any>, next: HttpHandler) {
    return this.tokenService.refreshToken().pipe(
      switchMap(() =>
        this.tokenService.getAccessToken().pipe(
          switchMap((newToken) => {
            this.isRefreshing = false
            this.refreshTokenSubject.next(newToken);
            const retryRequest = this.addTokenHeader(request, newToken!);
            return next.handle(retryRequest);
          }),
        ),
      ),
      catchError((refreshError) => {
        this.apiAuthService.logOutFromInterceptor();
        this.dialog.closeAll();
        return throwError(() => refreshError);
      }),
    );
  }
}
