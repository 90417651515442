import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import * as Sentry from '@sentry/angular';
import { environment } from '@environments/environment';
import packageInfo from '../package.json';

if (environment.name !== 'dev') {
  Sentry.init({
    dsn: environment.sentryUrl,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracePropagationTargets: [environment.apiUrl],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: environment.name,
    release: packageInfo.version,
  });
}

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
