import { Component, OnInit } from '@angular/core';
import {
  FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterModule } from '@angular/router';
import { NgxMaskDirective } from 'ngx-mask';
import { ApiAuthService } from '@core/services/api-auth.service';
import { ForgotPasswordResponse } from '@core/interfaces/auth.interface';
import { MutationResult } from 'apollo-angular';
import { PasswordVisibilityDirective } from '../password-visibility.directive';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { phoneNumberValidator } from '../phone-number-validator';
import { Subject, timer, takeUntil } from 'rxjs';
@UntilDestroy()
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    PasswordVisibilityDirective,
    RouterModule,
    NgxMaskDirective,
  ],
})
export class ForgotPasswordComponent implements OnInit {

  public loginForm!: FormGroup;

  public errorMessage: string = '';

  public timerValue: number | null = null;

  public timer$: Subject<void> = new Subject<void>();

  public isButtonDisabled: boolean = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly apiAuthService: ApiAuthService,
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  ngAfterContentChecked(): void {
    this.getTimeForSecondSend();
  }

  private getTimeForSecondSend(): void {
    this.apiAuthService.getTimerValue().pipe(untilDestroyed(this)).subscribe((value) => {
      this.timerValue = value;
      this.isButtonDisabled = value !== null && value > 0;
    });
  }

  public createForm(): void {
    this.loginForm = this.fb.group({
      phoneNumber: new FormControl('', [Validators.required, phoneNumberValidator()]),
    });
  }

  public onSubmitForm(): void {
    const { phoneNumber } = this.loginForm.value;
    const phoneNumberString = `380${phoneNumber.toString()}`;

    this.apiAuthService.forgotPassword(phoneNumberString).pipe(untilDestroyed(this)).subscribe(
      (res: MutationResult<{ forgotPassword: ForgotPasswordResponse }>) => {
        if (res.data && res.data.forgotPassword.success) {
          void this.router.navigateByUrl('auth');
          this.apiAuthService.startTimer();
        }
      },
      (error) => {
        this.errorMessage = 'Incorrect';
        this.loginForm.get('phoneNumber')!.setErrors({ incorrect: true });
      },
    );
  }
}
